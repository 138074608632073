.main__wrapper {
    margin-top: 12px;
    margin-bottom: 12px;
    margin-left: 28px;
    margin-right: 28px;
    width: calc(100% - 56px);
    display: block;
}

.empty {
    height: 10px;
}