.wrapperKYC {
    padding-top: 74px;
    margin-left: 16px;
    margin-right: 16px;
    overflow: scroll;
    height: 120%;
    -ms-user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.wrapper::-webkit-scrollbar {
    display: none;
}

.button {
    position: fixed;
    max-width: 500px;
    right: 16px;
    left: 16px;
    bottom: 24px;
    z-index: 555;
}

.description {
    font-family: 'SF UI Display Regular';
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.5px;
    color: #404040;
    margin: 0 auto;
    text-align: center;
}

.img__wrapper {
    max-width: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 40px;
}

.inputWrapper {
    margin-top: 40px;
    margin-left: 3px;
    margin-right: 3px;
}

.date {
    display: flex;
    justify-content: center;
    font-family: 'Montserrat Bold';
    font-style: normal;
    font-size: 12px;
    line-height: 12px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    color: #5C5C5C;
}

.wrapper {
    margin: 5px 16px 17px 16px;
}

.orderWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: calc(100% - 32px);
    height: 100%;
    margin-left: 16px;
    margin-right: 16px;
}

.orderErrorText {
    font-family: 'Montserrat Medium';
    font-style: normal;
    font-size: 17px;
    line-height: 17px;
    color: #5C5C5C;

    padding-top: 50px;
    padding-bottom: 24px;

    text-align: center;
}

.processWrapper {
    display: flex;
    justify-content: center;
    align-self: center;
    width: 100%;
    /* max-width: 400px; */
}

.main {
    position: fixed;
    width: 100%;
    max-width: 500px;
    height: 100%;
    bottom: 0;
    background-color: #F5F5F5;
    -ms-user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    z-index: 301;
}

.main__destination {
    position: fixed;
    width: 100%;
    max-width: 500px;
    height: 100%;
    bottom: 0;
    background-color: #F5F5F5;
    -ms-user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    z-index: 303;
}

.body__wrapper {
    margin-top: 120px;
    z-index: 303;
    -ms-overflow-style: none;
    scrollbar-width: none;
    width: 100%;
    overflow: scroll;
}

.body__wrapper::-webkit-scrollbar {
    display: none;
}

.body__main {
    padding-bottom: 150px;
    -ms-overflow-style: none;
    scrollbar-width: none;
    margin-top: 12px;
}

.body__main::-webkit-scrollbar {
    display: none;
}

.modal__shadow {
    height: 60px;
    padding-top: 15px;
    width: 100%;
    left: 0;
    background: linear-gradient(180deg, rgba(245, 245, 245, 0) 0%, #D1D1D1 100%);
    position: fixed;
    bottom: 0;
}

.selectors__wrapper {
    margin-top: 48px;
}

.category {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-family: 'Montserrat Bold';
    font-style: normal;
    font-size: 12px;
    line-height: 12px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    color: #5C5C5C;
    margin-left: 24px;
    margin-right: 10px;
    margin-bottom: 6px;
    -webkit-tap-highlight-color: transparent;
}

.notCard {
    width: 232px;
    height: 64px;
    background: #F7F7F7;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
    border-radius: 14px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 60px;
}

.cardDottedLine {
    width: calc(100% - 16px);
    height: calc(100% - 16px);
    border: 2px dotted #5C5C5C;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Montserrat Bold', sans-serif;
    font-style: normal;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #404040;
}

.cardText {
    width: 70%;
    text-align: left;
}

.addCardList {
    width: 100%;
    margin-top: 40px;
    display: flex;
    justify-content: center;
    font-family: 'Montserrat Bold', sans-serif;
    font-style: normal;
    font-size: 13px;
    line-height: 13px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #5C5C5C;
    -webkit-tap-highlight-color: transparent;
}

.minMaxWrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 55px;
    align-self: flex-start;
    align-self: center;
    -webkit-tap-highlight-color: transparent;
}

.limitBtn {
    font-family: 'Montserrat Bold';
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 14px;
    text-align: center;
    color: #404040;
    -webkit-tap-highlight-color: transparent;
}

.line {
    border-bottom: 1px solid #DADADA;
    height: 1px;
    margin: 0 auto;
    width: 80%;
}

.amountWrapper {
    display: flex;
    flex-direction: row;
    margin-top: 56px;
    width: 100%;
}

.wrapper__amount {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 88%;
}

.currency__balance {
    font-family: 'SF UI Display SemiBold';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 19px;
    text-align: center;
    letter-spacing: 1.5px;
    color: #999999;
    padding-bottom: 8px;
}

.input__wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 40px;
    position: relative;
    margin-left: 12px;
    margin-right: 6px;
}

.input__wrapper input {
    width: 90px;
    max-width: 70%;
    outline: none;
    border: none;
    background: transparent;
    font-family: 'Montserrat Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 40px;
    font-feature-settings: 'liga' off, 'kern' off;
    color: #404040;
    caret-color: #864DD9;
    -webkit-tap-highlight-color: transparent;
    resize: none;
    text-align: center;
    padding: 0;
    margin-left: 10px;
}

.input__wrapper input:focus::placeholder {
    color: transparent;
}

.input__wrapper input::-webkit-input-placeholder {
    font-family: 'Montserrat Medium';
    font-style: normal;
    font-size: 40px;
    line-height: 40px;
    letter-spacing: 0.5px;
    color: #404040;
}

.input__wrapper input:-ms-input-placeholder {
    font-family: 'Montserrat Medium';
    font-style: normal;
    font-size: 40px;
    line-height: 40px;
    letter-spacing: 0.5px;
    color: #404040;
}

.input__wrapper input::placeholder {
    font-family: 'Montserrat Medium';
    font-style: normal;
    font-size: 40px;
    line-height: 40px;
    letter-spacing: 0.5px;
    color: #404040;
}

.input__code {
    width: 100px;
    padding-right: 5px;
    font-family: 'Montserrat SemiBold';
    font-size: 20px;
    line-height: 20px;
    font-feature-settings: 'liga' off, 'kern' off;
    color: #404040;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    align-self: flex-end;
    -webkit-tap-highlight-color: transparent;
    margin-bottom: 4px;
}

.loader {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 10px;
}

.paymentWrapper {
    display: flex;
    flex-direction: row;
    max-width: 500px;
    justify-content: space-between;
    margin-top: 7px;
}

.detailsWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 50px;
    width: 56%;
    background: #EBEBEB;
    box-shadow: inset 0px 0px 16px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
}

.detailsAccount {
    font-family: 'Montserrat Bold';
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 16px;
    color: #5C5C5C;
}

.changeBtn {
    display: flex;
    justify-content: space-around;
    align-items: center;
    background: #404040;
    box-shadow: 0px 6px 10px rgba(64, 64, 64, 0.2);
    border-radius: 10px;
    height: 50px;
    width: calc(40% - 32px);
    font-family: 'Montserrat Medium';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.5px;
    color: #F7F7F7;
    text-decoration: none;
    padding-left: 16px;
    padding-right: 16px;

    position: relative;
}

.changeArrow {
    width: 20px;
}



/* btns */
.buttons {
    display: flex;
    flex-direction: row;
    bottom: 24px;
    position: fixed;
    max-width: 500px;
    justify-content: space-between;
    right: 16px;
    left: 16px;
}

.button__back {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 64px;
    height: 50px;
    border: none;
    outline: none;
    background: #EBEBEB;
    box-shadow: 0 6px 10px rgba(64, 64, 64, 0.15);
    border-radius: 10px;
    margin-right: 16px;
    -webkit-tap-highlight-color: transparent;
}

.currency__item {
    width: calc(100% - 48px);
    margin-left: 16px;
}

.payment__name {
    font-family: 'SF UI Display Bold';
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 13px;
    letter-spacing: 1.75px;
    color: #999999;
    text-transform: uppercase;
    margin-left: 16px;
}

.give__get {
    font-family: 'Montserrat Semibold';
    font-style: normal;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: .5px;
    color: #5C5C5C;
    
    text-align: center;
    padding-bottom: 6px;
}

@media screen and (min-width: 500px) {
    .main {
        left: calc((100% - 500px) / 2);
    }
    .main__destination {
        margin-left: calc((100% - 500px)/2);
    }
    .buttons {
        left: calc((100% - 464px) / 2);
        max-width: 464px;
    }
}
