.currency__wrapper{
    width: 40px;
    height: 40px;
    background: none;
    display: flex;
    flex-direction: row-reverse;
    border-radius: 50%;
    border: 2px;
    border-style: solid;
}

.little__circle {
    display: flex;
    flex-direction: column-reverse;
    align-self: flex-end;
    margin-left: -16px;
    margin-bottom: -2px;
    background: #F9F9F9;
    border-radius: 50%;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
    padding: 1.5px;
}

.btg {
    border-radius: 50%;
    width: 24px;
    height: 24px;
    border: 4px solid #E1A600;
    border-style: solid;
    margin-top: 4px;
    margin-right: 4px;
}