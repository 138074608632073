.filter__wrapper {
    display: flex;
    flex-direction: row;
    max-width: 500px;
    width: 70%;
    margin-left: calc(14% + 5px);
    justify-content: space-around;
}

.filter__item {
    width: auto;
    height: 50px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    -webkit-tap-highlight-color: transparent;
}

.filter__value {
    font-family: 'Montserrat Bold';
    font-style: normal;
    /* font-weight: bold; */
    font-size: 16px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #404040;
    display: flex;
}

.filter__notValue {
    font-family: 'Montserrat Bold';
    font-style: normal;
    /* font-weight: bold; */
    font-size: 16px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #999999;
    display: flex;
}

.filter__point {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: #5C5C5C;
    margin-top: 4px;
}

.qrCode {
    width: 100%;
    justify-content: center;
    align-items: center;
    align-self: center;
    text-align: center;
    margin-top: 20px;
    position: relative;
}

.data__wrapper {
    width: calc(100% - 32px);
    display: inline-flex;
    margin-left: 16px;
    margin-right: 16px;
}

.main__wrapper {
    margin-top: 12px;
    margin-bottom: 12px;
    margin-left: 28px;
    margin-right: 28px;
    width: calc(100% - 56px);
    display: block;
}

.fee__wrapper {
    display: flex;
    flex-direction: row;
    height: 40px;
    width: 100%;
    margin-bottom: 10px;
}

.fee__icon {
    width: 10%;
    margin-top: 8px;
}

.fee__text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    flex-wrap: wrap;
    width: 80%;
    font-family: 'SF UI Display Regular', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: .5px;
    color: #5C5C5C;
}

.line {
    width: 70%;
    border-top: 1px solid #C4C4C4;
    margin: 0 auto;
    padding-top: 5px;
    padding-bottom: 12px;
}

.empty {
    height: 10px;
}