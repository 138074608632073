.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    min-height: 100vh;
    flex-direction: column;
    max-width: 500px;
    margin: 0 auto;
}

.title {
    margin-top: -10px;
    font-family: 'Montserrat Bold', sans-serif;
    font-size: 24px;
    color: #404040
}

.description {
    margin-top: 10px;
    font-family: 'SF UI Display Regular', sans-serif;
    font-size: 20px;
    color: #5C5C5C;
    margin-right: 24px;
    margin-left: 24px;
}

.btn {
    margin-top: 5px;
    font-family: 'SF UI Display Regular', sans-serif;
    font-style: normal;
    color: #404040;
}

.footer {
    position: absolute;
    bottom: 60px;
    justify-content: center;
    text-align: center;
    flex-direction: column;
}

.circleWrapper {
    display: flex;
    flex-direction: row;
    margin: 0 auto;

    margin-top: 70px;
}

.circle {
    height: 56px;
    width: 56px;
    border-radius: 50%;
    background-color: #404040;
    justify-content: center;
    align-items: center;
    margin-left: 25px;
    margin-right: 25px;
}

.verstion {
    flex-direction: row;
    font-family: 'Montserrat Bold', sans-serif;
    font-size: 16px;
    text-align: 'center';
    color: '#404040';
}

.hash {
    font-family: 'SF UI Display SemiBild', sans-serif;
    font-size: 14px;
    color: '#5c5c5c';
    margin-top: 7px;
}