.wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 60px;
}

.wrapper__min {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    height: 40px;
}

.item__btn {
    border: 2px solid #404040;
    box-sizing: border-box;
    border-radius: 10px;
    outline: none;
    width: 23%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Montserrat Bold';
    font-style: normal;
    font-size: 16px;
    line-height: 16px;
    color: #5C5C5C;
    letter-spacing: .5px;
    -webkit-tap-highlight-color: transparent;
}

.item__btn__min {
    border: 2px solid #404040;
    box-sizing: border-box;
    border-radius: 10px;
    outline: none;
    width: 48%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Montserrat Bold';
    font-style: normal;
    font-size: 16px;
    line-height: 16px;
    color: #5C5C5C;
    letter-spacing: .5px;
    -webkit-tap-highlight-color: transparent;
}

.item__btn__min__inverse {
    background: #404040;
    color: #F5F5F5;
    border: 2px solid #404040;
    box-sizing: border-box;
    border-radius: 10px;
    outline: none;
    width: 48%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Montserrat Bold';
    font-style: normal;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: .5px;
    transition-property: all;
    transition-duration: 0.3s;
}