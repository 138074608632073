.wrapper {
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: #F5F5F5;
    overflow: scroll;
    -webkit-appearance: none;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
    position: fixed;
    z-index: 1900;
    top: 0;
    overflow: hidden;
}

.main {
    max-width: 500px;
    width: 100%;
    height: 100%;
}

.item__slider {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: calc(100% - 270px);
    width: 100%;
}

.img__wrapper {
    height: 500px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    padding-bottom: 10px;
}

.text {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    left: 32px;
    right: 32px;
    padding-bottom: 40px;
    min-height: 100px;
    position: absolute;
    bottom: 100px;
    padding-top: 10px;
}

.title {
    font-family: 'Montserrat Medium';
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 30px;
    text-align: center;
    color: #404040;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 12px;
    letter-spacing: -1px;
}

.description {
    font-family: 'SF UI Display Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.5px;
    color: #5C5C5C;
    text-align: center;
}

.skip {
    position: absolute;
    width: 50px;
    height: 40px;
    left: 31px;
    bottom: 52px;
    font-family: 'Montserrat Bold';
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 12px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    color: #999999;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    -ms-user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
}

.btn1 {
    width: 50px;
    height: 50px;
    cursor: pointer;
    user-select: none;
    position: absolute;
    bottom: 50px;
    font: 16px/30px 'sans-serif';
    color: #F7F7F7;
    outline: none;
    -webkit-tap-highlight-color: transparent;
    background: #404040;
    box-shadow: 0px 6px 10px rgba(64, 64, 64, 0.15);
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Montserrat Medium';
    font-style: normal;
    font-size: 16px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.5px;
    right: 0;
}

.btn {
    width: 50px;
    height: 50px;
    cursor: pointer;
    user-select: none;
    position: absolute;
    bottom: 50px;
    font: 16px/30px 'sans-serif';
    color: #F7F7F7;
    outline: none;
    -webkit-tap-highlight-color: transparent;
    background: #404040;
    box-shadow: 0px 6px 10px rgba(64, 64, 64, 0.15);
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Montserrat Medium';
    font-style: normal;
    font-size: 16px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.5px;
    right: 0;
    animation: animate 0.25s ease-in;
    animation-fill-mode: backwards;
}

@keyframes animate {
    0% {
        width: 100%;
    }
    100% {
        width: 50px;
    }
}

.newBtn {
    width: 50px;
    height: 50px;
    cursor: pointer;
    user-select: none;
    position: absolute;
    bottom: 50px;
    font: 16px/30px 'sans-serif';
    color: #F7F7F7;
    outline: none;
    -webkit-tap-highlight-color: transparent;
    background: #404040;
    box-shadow: 0px 6px 10px rgba(64, 64, 64, 0.15);
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Montserrat Medium';
    font-style: normal;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 0.5px;
    right: 0;
    animation: animateThis 0.25s ease-in;
    animation-fill-mode: forwards;
    white-space: nowrap;
}

@keyframes animateThis {
    0% {
        width: 50px;
    }
    100% {
        width: calc(100% - 32px);
        text-align: center;
        white-space: normal;
    }
}