.content {
    padding-top: 40px;
    padding-left: 16px;
    padding-right: 16px;
}

.content::-webkit-scrollbar {
    display: none;
  }

.content__scroll {
    height: calc(100% - 101px);
    margin-bottom: 100px;
}