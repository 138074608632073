body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@font-face {
  font-family: 'SF UI Display Regular';
  src: local('SF UI Display Regular'), url('./fonts/sf_ui_display_regular.ttf') format('truetype');
}

@font-face {
  font-family: 'SF UI Display Heavy';
  src: local('SF UI Display Heavy'), url('./fonts/SFUIDisplay-Heavy.ttf') format('truetype');
}

@font-face {
  font-family: 'SF UI Display Bold';
  src: local('SF UI Display Bold'), url('./fonts/SFUIDisplay-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'SF UI Display Semibold';
  src: local('SF UI Display Semibold'), url('./fonts/SFUIDisplay-Semibold.ttf') format('truetype');
}

@font-face {
  font-family: 'SF UI Display Medium';
  src: local('SF UI Display Medium'), url('./fonts/SFUIDisplay-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat Medium';
  src: local('Montserrat Medium'), url('./fonts/Montserrat-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat SemiBold';
  src: local('Montserrat SemiBold'), url('./fonts/Montserrat-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat Bold';
  src: local('Montserrat Bold'), url('./fonts/Montserrat-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat ExtraBold';
  src: local('Montserrat ExtraBold'), url('./fonts/Montserrat-ExtraBold.ttf') format('truetype');
}
